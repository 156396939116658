import { Component } from '@angular/core';

type HowToWorkStep = {
    number: number;
    title: string;
    description: string;
};
@Component({ selector: 'lce-web-section-b', templateUrl: './lce-web-section-b.component.html' })
export class LCEWebSectionBComponent {
    howtoWorks: HowToWorkStep[] = [
        {
            number: 1,
            title: 'lce.website.howWorkSteps.step0.title',
            description: 'lce.website.howWorkSteps.step0.description',
        },
        {
            number: 2,
            title: 'lce.website.howWorkSteps.step1.title',
            description: 'lce.website.howWorkSteps.step1.description',
        },
        {
            number: 3,
            title: 'lce.website.howWorkSteps.step2.title',
            description: 'lce.website.howWorkSteps.step2.description',
        },
        {
            number: 4,
            title: 'lce.website.howWorkSteps.step3.title',
            description: 'lce.website.howWorkSteps.step3.description',
        },
    ];
}
