import {Injectable} from '@angular/core';
import {environment} from '@lce-web/environments/environment';
import packageInfo from '@package.json';
import {XSBuiltEnvironmentName, XSLanguage} from '@xs/base';
import {LCEEnvironmentService} from '@lce/core';

@Injectable({providedIn: 'root'})
export class LCEWebEnvironmentService extends LCEEnvironmentService {
    
    public getRequestor(): string {
        return environment.common.requestor;
    }

    public getDefaultLanguage(): XSLanguage {
        return environment.common.defaultLanguage;
    }

    public getAvailableLanguages(): XSLanguage[] {
        return environment.common.availableLanguages;
    }

    public getAppPrefix(): string {
        return 'lce';
    }

    public getAppName(): string {
        return packageInfo.name;
    }

    public getAppVersion(): string {
        return packageInfo.version;
    }

    public getAppDisplayName(): string {
        return environment.common.appDisplayName;
    }

    public getBuiltEnvironmentName(): XSBuiltEnvironmentName {
        return environment.name;
    }

    public isElectron(): boolean {
        return false;
    }
}
