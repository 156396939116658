import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEWebLandingPageComponent} from '@lce-web/features/landing-page/lce-web-landing-page.component';
import {LCEWebSectionAComponent} from '@lce-web/features/landing-page/section-a/lce-web-section-a.component';
import {LCEWebSectionBComponent} from '@lce-web/features/landing-page/section-b/lce-web-section-b.component';
import {LCEWebSectionCComponent} from '@lce-web/features/landing-page/section-c/lce-web-section-c.component';
import {LCEWebSectionDComponent} from '@lce-web/features/landing-page/section-d/lce-web-section-d.component';
import {LCEWebSharedModule} from '@lce-web/shared/lce-web-shared.module';
import {LCEWebSectionBComponentItem as LCEWebSectionBItemComponent} from './landing-page/section-b/section-b-item/lce-web-section-b-item.component';
import {LCEWebSectionCItemComponent} from './landing-page/section-c/section-c-item/lce-web-section-c-item.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LCEWebContactPageComponent} from './contact/lce-web-contact-page.component';
import {LCEWebAboutUsComponent} from './about-us/lce-web-about-us.component';
import {LCEWebTeamComponent} from './team/lce-web-team.component';
import {LCEWebHowWorkComponent} from './how-work/lce-web-how-work.component';
import {LCEWebCertificateOrderProcessComponent} from './certificate-order-process/lce-web-certificate-order-process.component';
import {LCEWebServicesComponent} from '@lce-web/features/services/lce-web-services.component';
import {LCENewsFeatureService, LCESharedModule, LCESmartCityFeatureService} from '@lce/shared';
import {LCEWebSmartCityComponent} from '@lce-web/features/smartcity/lce-web-smartcity.component';
import {LCEWebNewsComponent} from '@lce-web/features/news/lce-web-news.component';

@NgModule({
    imports: [
        // ***
        CommonModule,
        LCEWebSharedModule,
        InputTextareaModule,
        LCESharedModule
    ],
    declarations: [
        LCEWebServicesComponent,

        LCEWebLandingPageComponent,
        LCEWebSectionAComponent,
        LCEWebSectionBComponent,
        LCEWebSectionCComponent,
        LCEWebSectionDComponent,
        LCEWebSectionBItemComponent,
        LCEWebSectionCItemComponent,

        LCEWebContactPageComponent,
        LCEWebAboutUsComponent,
        LCEWebTeamComponent,
        LCEWebHowWorkComponent,

        LCEWebCertificateOrderProcessComponent,

        LCEWebSmartCityComponent,

        LCEWebNewsComponent
    ],
    exports: [
        // ***
    ],
    providers: [LCESmartCityFeatureService, LCENewsFeatureService]
})
export class LCEWebFeaturesModule {
}
