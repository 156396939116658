<div class="xs-flex-column-center xs-width-full xs-flex-1 {{ styleClass }}">
    <div class="lce-smartcity-main-container">
        <div class="xs-absolute-center-vh">
            <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
        </div>

        <div *ngIf="hasError() && !loader.isRunning()" class="xs-absolute-center-vh">
            <xs-error
                    [actionButton]="errorRetryButton"
                    [error]="error"
                    [showActionButton]="true"
                    [showErrorDetailButton]="true"
                    message="lce.shared.smartcity.label.errorMessageRetrieveArticle"
                    mode="block"
                    subMessage="xs.core.error.contactAdminMessage">
            </xs-error>
        </div>

        <div *ngIf="!hasError() && !loader.isRunning() && (smartCityArticle | xsIsNotEmpty)"
             class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-mb-15">
            <!-- === Audit === -->
            <div class="xs-flex-row xs-align-items-center xs-width-fit-content">
                <lce-user-resource-audit [data]="smartCityArticle!"></lce-user-resource-audit>
            </div>
            <!-- === Actions  === -->
            <div class="xs-flex-row xs-align-items-center xs-width-fit-content">
                <xs-button
                        [icon]="ICON.edit"
                        [tooltipText]="TR_BASE_TOOLTIP + 'edit'"
                        class="xs-mlr-40"
                        label="xs.core.label.edit"
                        routerLink="/smartcity/articles/update/{{ smartCityArticle!.id }}"
                        size="intermediate"
                        type="text">
                </xs-button>
                <xs-button [icon]="ICON.ellipsisVertical" [transform]="false" size="intermediate" tooltipText="ch.features.smartcity.label.moreActions" type="icon">
                </xs-button>
            </div>
        </div>

        <div *ngIf="!hasError() && !loader.isRunning() && (smartCityArticle | xsIsNotEmpty)">
            <p-splitter [gutterSize]="1" [minSizes]="[65,20]" [panelSizes]="[70, 30]">
                <ng-template pTemplate>
                    <div class="xs-flex-column xs-width-full xs-mt-50 xs-plr-25">
                        <!-- === Article Head === -->
                        <div class="xs-flex-column-center xs-width-full">
                            <div class="xs-flex-column xs-width-full xs-max-width-750">
                                <div class="xs-flex-column xs-width-full">
                                    <lce-smartcity-article-type
                                            [darkness]="true"
                                            [small]="true"
                                            [type]="smartCityArticle!.type"
                                            styleClass="xs-mb-15">
                                    </lce-smartcity-article-type>
                                    <span class="xs-display-block xs-font-size-medium xs-font-weight-500"> {{ smartCityArticle!.title }} </span>
                                    <span *ngIf="smartCityArticle!.subTitle | xsIsNotEmpty" class="xs-display-block xs-font-size-intermediate xs-mt-5">
                                        {{ smartCityArticle!.subTitle }}
                                    </span>

                                    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-mt-30 xs-md-plr-15">
                                        <span class="xs-display-block xs-font-size-intermediate xs-color-secondary">
                                            {{ smartCityArticle!.createdOn | xsDate : createdOnDateFormat }}
                                        </span>
                                        <lce-smartcity-article-stats-icons
                                                [readCount]="smartCityArticle!.readCount"
                                                [scanCount]="smartCityArticle!.scanCount"
                                                [shareCount]="smartCityArticle!.shareCount"
                                                styleClass="xs-width-225 xs-color-secondary">
                                        </lce-smartcity-article-stats-icons>
                                    </div>
                                </div>
                                <div class="xs-flex-column xs-width-full xs-mt-10">
                                    <img [src]="smartCityArticle!.coverImage" alt="" class="xs-width-full xs-border-radius-default"/>
                                    <!-- TODO: a text describing the image cover should be display here ...  -->
                                </div>
                            </div>
                        </div>

                        <!-- === Article Content === -->
                        <div class="xs-flex-column xs-width-full xs-mt-50">
                            <div
                                    *ngIf="smartCityArticle!.description | xsIsNotEmpty"
                                    [innerHTML]="smartCityArticle!.description | xsTrim | xsSafe : 'html'"
                                    class="xs-flex-row-center xs-font-weight-600 xs-mb-15">
                            </div>
                            <div
                                    [innerHTML]="smartCityArticle!.content | xsTrim | xsSafe : 'html'"
                                    class="xs-mtb-15 xs-line-height-1-5 xs-text-align-justify">
                            </div>
                        </div>

                    </div>
                </ng-template>
                <ng-template pTemplate>
                    <div class="xs-flex-column xs-align-items-center xs-width-full xs-plr-15 xs-pt-50">
                        <div class="xs-flex-column-center xs-width-full xs-min-width-100 xs-max-width-175 xs-mb-30">
                            <xs-qrcode [data]="smartCityArticle!.qrCode!"
                                       [downloadShowIcon]="false"
                                       [download]="true"
                                       alt="Smart City QRCode"
                                       downloadFileName="SmartCity.QRCode.{{ smartCityArticle!.code }}">
                            </xs-qrcode>
                        </div>

                        <div class="xs-width-full xs-card xs-card-border xs-mb-30">
                            <div class="xs-card-header">
                                <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="categoriesCard" [expanded]="true"
                                          [text]="TR_BASE + 'categoryTitle'">
                                </xs-title>
                            </div>
                            <div #categoriesCard class="xs-card-content">
                                <xs-chips *ngIf="categoryChips | xsIsNotEmpty" [chips]="categoryChips" [small]="true"></xs-chips>
                                <div *ngIf="categoryChips | xsIsEmpty" class="xs-flex-row-center">
                                    <xs-icon [value]="ICON.categories" color="discrete" size="40px" styleClass="xs-opacity-40"></xs-icon>
                                </div>
                            </div>
                        </div>

                        <div class="xs-width-full xs-card xs-card-border xs-mb-30">
                            <div class="xs-card-header">
                                <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="tagsCard" [expanded]="true" [text]="TR_BASE + 'tagTitle'"></xs-title>
                            </div>
                            <div #tagsCard class="xs-card-content">
                                <xs-chips *ngIf="tagChips | xsIsNotEmpty" [chips]="tagChips" [small]="true"></xs-chips>
                                <div *ngIf="tagChips | xsIsEmpty" class="xs-flex-row-center">
                                    <xs-icon [value]="ICON.tags" color="discrete" size="40px" styleClass="xs-opacity-40"></xs-icon>
                                </div>
                            </div>
                        </div>

                        <div class="xs-width-full xs-card xs-card-border">
                            <div class="xs-card-header">
                                <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="reviewersContainer" [expanded]="true" [text]="TR_BASE + 'reviewerTitle'">
                                </xs-title>
                            </div>
                            <div #reviewersContainer class="xs-card-content">
                                <div class="xs-flex-column">
                                    <div *ngIf="reviewers | xsIsEmpty" class="xs-flex-row-center xs-mb-30">
                                        <span class="xs-display-block xs-font-size-small xs-color-secondary">{{ TR_BASE + 'noReviewer' | translate }} </span>
                                    </div>
                                    <div class="xs-flex-column">
                                        <lce-user-ivar *ngFor="let reviewer of reviewers" [data]="reviewer.user" avatarSize="small" styleClass="xs-mb-15"></lce-user-ivar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-splitter>
        </div>
    </div>
</div>