import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({selector: 'lce-use-mobile-version', templateUrl: './lce-use-mobile-version.component.html'})
export class LCEUseMobileVersionComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() showBorder?: boolean;

    @Output() continueWithWebVersionEvent = new EventEmitter<void>();

    ngOnInit(): void {
    }

    public continueWithWebVersion(): void {
        this.continueWithWebVersionEvent.emit();
    }

}