import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSColor, XSColorStr, XSSize, XSSizeStr} from '@xs/common';
import {XSDeviceDetectorService} from '@xs/core';

@Component({selector: 'xs-feature-item', templateUrl: './xs-feature-item.component.html'})
export class XSFeatureItemComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subTitleStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() showBorder?: boolean;

    @Input() backgroundColor?: string;
    @Input() backgroundColorGradient?: string;

    @Input() backgroundImage?: string;

    @Input() gradientDegree?: number;
    @Input() gradientPercent?: number;
    @Input() gradientColor?: string;

    @Input() title: string;
    @Input() subTitle?: string;

    @Input() titleColor?: string;
    @Input() subTitleColor?: string;

    @Input() iconStyleClass?: string;
    @Input() iconContainerStyleClass?: string;
    @Input() iconContainerBackgroundColor?: string;
    @Input() iconValueStyleClass?: string;
    @Input() iconSize?: XSSize | XSSizeStr | string;
    @Input() iconColor?: XSColor | XSColorStr | string;
    @Input() icon: string;

    @Input() direction?: 'horizontal' | 'vertical';
    @Input() autoDirection?: boolean;

    @Input() clickable?: boolean;

    @Output() clickEvent = new EventEmitter<Event>();

    isMobile: boolean;

    constructor(private deviceDetectorService: XSDeviceDetectorService) {
    }

    ngOnInit(): void {
        this.validate();
        this.handleDefaults();
        this.isMobile = this.deviceDetectorService.isMobile();
    }

    public getBackgroundImage(): string | undefined {
        if (this.backgroundImage) {
            return `linear-gradient(90deg, ${this.gradientColor} ${this.gradientPercent}%, transparent 100%), url("${this.backgroundImage}")`;
        }
        return undefined;
    }

    public getBackgroundColor(): string | undefined {
        if (this.backgroundColorGradient) {
            return `linear-gradient(${this.gradientDegree}deg, ${this.gradientColor} ${this.gradientPercent}%, ${this.backgroundColorGradient} 100%)`;
        }
        return this.backgroundColor;
    }

    public hasBackgroundImage(): boolean {
        return !XSUtils.isEmpty(this.backgroundImage);
    }
 
    private validate(): void {
        XSAssert.notEmpty(this.title, 'title');
        XSAssert.notEmpty(this.icon, 'icon');
    }

    private handleDefaults(): void {
        if (XSUtils.isNull(this.direction)) this.direction = 'horizontal';
        if (XSUtils.isNull(this.gradientDegree)) this.gradientDegree = 50;
        if (XSUtils.isNull(this.gradientPercent)) this.gradientPercent = 50;
        if (XSUtils.isEmpty(this.gradientColor)) this.gradientColor = 'rgb(250, 250, 250)';
        if (this.autoDirection === true) this.isMobile ? this.direction = 'vertical' : this.direction = 'horizontal';
        if (XSUtils.isNull(this.clickable)) this.clickable = this.clickEvent.observers.length > 0;
    }
}
