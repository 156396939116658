import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {XS_STR_EMPTY} from '@xs/base';

@Injectable()
export class LCESmartCityFeatureService {

    state: {
        title: string;
        subTitle?: string;
        showNewArticleButton: boolean;
        showRefreshButton: boolean;
        showBackButton: boolean;
    };

    private refreshSubject = new Subject<void>();
    onRefresh = this.refreshSubject.asObservable();

    private backSubject = new Subject<void>();
    onBack = this.backSubject.asObservable();

    constructor() {
        this.state = {
            title: XS_STR_EMPTY,
            subTitle: XS_STR_EMPTY,
            showNewArticleButton: true,
            showRefreshButton: true,
            showBackButton: true
        };
    }

    public emitBack() {
        this.backSubject.next();
    }

    public emitRefresh() {
        this.refreshSubject.next();
    }
}
