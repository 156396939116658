import {Component} from '@angular/core';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';
import {LCESmartCityFeatureService} from '@lce/shared';
import {XSNavigationService} from '@xs/core';

@Component({
    selector: 'lce-web-smartcity',
    templateUrl: 'lce-web-smartcity.component.html',
    host: {class: 'xs-flex-column xs-flex-1'}
})
export class LCEWebSmartCityComponent {

    readonly ICON = LCE_MBO_ICON;

    constructor(private navigationService: XSNavigationService, public featureService: LCESmartCityFeatureService) {
        this.featureService.onBack.subscribe(() => this.back());
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }

    public back(): void {
        this.navigationService.back();
    }
}
