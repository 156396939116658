import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE, LCESmartCityArticleCard, LCESmartCityArticlePredefinedFilter, LCESmartCityArticleSearch, LCESmartCityArticleService, LCESmartCityArticleType} from '@lce/core';
import {XSPagination, XSSearchResult, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSButton, XSChip, XSDeviceDetectorService, XSPaginationLoadMoreOptions} from '@xs/core';
import {Paginator} from 'primeng/paginator/paginator';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESmartCityFeatureService} from '../lce-smartcity-feature.service';

@Component({
    selector: 'lce-smartcity-articles-web',
    templateUrl: './lce-smartcity-articles-web.component.html',
    host: {class: 'xs-flex-column xs-flex-1'}
})
export class LCESmartCityArticlesWebComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.smartcity.articles.';
    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 3;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;
    readonly PAGINATOR_ROWS: number = 6;
    readonly PAGINATOR_ROWS_PER_PAGE_OPTIONS: number[] = [6, 12, 24];
    readonly PRIMARY_LOADER_ID = XSUtils.uuid();
    readonly SECONDARY_LOADER_ID = XSUtils.uuid();
    @Input() styleClass?: string;
    @ViewChild('pPaginator') pPaginatorComponent: Paginator;
    searchText: string;
    nArticles: number = 0;
    articles: LCESmartCityArticleCard[] = [];
    resultSubText: string;
    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.search()
    };
    firstInitialization: boolean = true;

    articleTypeChips: XSChip[] = [
        {label: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.NOTIFICATION},
        {label: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.AMBER_ALERT},
        {label: LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE + LCESmartCityArticleType.PUBLIC_EVENT}];

    pagination: XSPagination = {page: 0, size: this.PAGINATOR_ROWS};
    articleSearch: LCESmartCityArticleSearch = {paginationPage: this.pagination.page, paginationSize: this.pagination.size};
    paginationOptions: XSPaginationLoadMoreOptions = {
        fnSearch: (pagination: XSPagination) => {
            this.buildSearch(pagination);
            return this.smartCityArticleService.searchAsCards(this.articleSearch);
        }
    };
    isMobile: boolean;

    private readonly TR_BASE_PREDEFINED_FILTER: string = 'lce.shared.smartcity.predefinedFilter.';
    predefinedFilterChips: XSChip[] = [
        {label: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.LATEST},
        {label: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.POPULAR},
        {label: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.MOST_READ},
        {label: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.MOST_SCANNED},
        {label: this.TR_BASE_PREDEFINED_FILTER + LCESmartCityArticlePredefinedFilter.MOST_SHARED}

    ];
    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private loaderService: XSLoaderService,
        private deviceDetectorService: XSDeviceDetectorService,
        private smartCityArticleService: LCESmartCityArticleService,
        private featureService: LCESmartCityFeatureService) {

        this.featureService.state.title = this.TR_BASE + 'title';
        this.featureService.state.subTitle = this.TR_BASE + 'subTitle';
        this.featureService.state.showNewArticleButton = true;
        this.featureService.state.showRefreshButton = true;
        this.featureService.state.showBackButton = false;
        this.featureService.onRefresh.subscribe(() => this.update());
        this.isMobile = this.deviceDetectorService.isMobile();

        this.handleRouting();
    }

    ngOnInit(): void {
        this.search();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public handlePaginationError(error: any): void {
        this.error = error;
    }

    public handlePaginationLoad(searchResults: XSSearchResult<any>): void {
        this.articles = this.articles.concat(searchResults.data);
        this.nArticles = searchResults.total;
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public update(): void {
        this.search();
    }

    public onInputSearchChange(): void {
        this.search();
    }

    public onPredefinedFilterChange(): void {
        this.resetPagination();
        this.search();
    }

    public onTypeFilterChange(): void {
        this.resetPagination();
        this.search();
    }

    public onPaginationNumberChange() {
        this.search();
    }

    public handlePagination(event: any) {
        this.pagination.page = event.page;
        this.pagination.size = event.rows;
        this.search();
    }

    public isPaginable(): boolean {
        return this.nArticles > this.PAGINATOR_ROWS_PER_PAGE_OPTIONS[0];
    }

    public isSecondaryLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.SECONDARY_LOADER_ID);
    }

    private resetPagination(): void {
        this.pagination.page = 0;
    }

    private search(searchText?: string, pagination?: XSPagination): void {
        this.startLoader();
        this.error = undefined;
        this.buildSearch(pagination);
        this.subscription.add(this.smartCityArticleService
            .searchAsCards(this.articleSearch)
            .pipe(finalize(() => this.stopLoader()))
            .subscribe({
                next: searchResult => {
                    this.articles = searchResult.data;
                    this.nArticles = searchResult.total;
                    this.firstInitialization = false;
                    this.buildResultSubText();
                },
                error: error => this.error = error
            })
        );
    }

    private buildSearch(pagination?: XSPagination): void {
        if (!XSUtils.isEmpty(pagination)) this.pagination = pagination!;
        this.articleSearch = {
            query: this.searchText?.trim(),
            //TODO with chip
            // types: this.typeFilters,
            paginationPage: this.pagination.page,
            paginationSize: this.pagination.size
        };
        XSUtils.removeNullAndUndefinedEntries(this.articleSearch);
        XSUtils.removeEmptyObjectEntries(this.articleSearch);
    }

    private startLoader(): void {
        if (this.firstInitialization) {
            this.loaderService.startLoader(this.PRIMARY_LOADER_ID);
        } else {
            this.loaderService.startLoader(this.SECONDARY_LOADER_ID);
        }
    }

    private stopLoader(): void {
        if (this.loaderService.isLoaderRunning(this.PRIMARY_LOADER_ID)) {
            this.loaderService.stopLoader(this.PRIMARY_LOADER_ID);
        }
        if (this.loaderService.isLoaderRunning(this.SECONDARY_LOADER_ID)) {
            this.loaderService.stopLoader(this.SECONDARY_LOADER_ID);
        }
    }

    private buildResultSubText(): void {
        // TODO: Build custom message depending of the filters below the total number of results.
        //const currentLanguage: XSLanguage = this.translationService.getCurrentLanguage();
        this.resultSubText = 'Articles les plus scannés depuis le début de l\'année 2023';
    }

    private handleRouting(): void {
        const routerStateData = this.router.getCurrentNavigation()?.extras?.state;
        if (!XSUtils.isEmpty(routerStateData?.type)) {
            //TODO with chip
            // this.typeFilters = [this.toType(routerStateData!.type)];
        }

        const paramType = this.activatedRoute.snapshot.queryParamMap.get('type');
        if (!XSUtils.isEmpty(paramType)) {
            //TODO with chip
            // this.typeFilters = [this.toType(paramType)];
        }
    }

    private toType(paramType: any): LCESmartCityArticleType {
        const pType = XSUtils.toEnum<LCESmartCityArticleType>(paramType, LCESmartCityArticleType);
        if (XSUtils.isEmpty(pType)) {
            throw new Error(`failed to cast parameter [${paramType}] to LCESmartCityArticleType.`);
        }
        return pType!;
    }
}
