import { Component, Input } from '@angular/core';
import { XSTranslationService } from '@xs/common';
import { XSLanguage } from '@xs/base';

@Component({
    selector: 'lce-web-translation-button',
    template: `<xs-button (clickEvent)="onLanguageChange()" styleClass="lce-web-app-primary-color" type="text" label="{{ isCurrentLanguageFRENCH() ? 'EN' : 'FR' }}"></xs-button>`,
})
export class LCEWebTranslationButtonComponent {
    @Input() styleClass?: string;

    constructor(private translationService: XSTranslationService) {}

    public isCurrentLanguageFRENCH(): boolean {
        return this.translationService.getCurrentLanguage() === XSLanguage.FRENCH;
    }

    public onLanguageChange() {
        const language = this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XSLanguage.FRENCH : XSLanguage.ENGLISH;
        this.translationService.changeCurrentLanguage(language).subscribe();
    }
}
