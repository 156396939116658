import {Injectable, NgZone} from '@angular/core';
import {XSErrorHandler} from '@xs/core';
import {LCEWebErrorService} from '@lce-web/services/lce-web-error.service';

@Injectable({providedIn: 'root'})
export class LCEWebErrorHandler extends XSErrorHandler {
    constructor(protected ngZone: NgZone, protected errorService: LCEWebErrorService) {
        super(ngZone, errorService);
    }
}
