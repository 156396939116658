import { Component, OnInit } from '@angular/core';
import { LCE_WEB_ICON } from '@lce-web/core/constants/lce-web-icon.constant';

@Component({
    selector: 'lce-web-about-us',
    templateUrl: './lce-web-about-us.component.html',
})
export class LCEWebAboutUsComponent implements OnInit {
    readonly COMING_SOON_IMAGEURL: string = 'assets/images/mongo.jpg';
    readonly COMING_SOON_MESSAGE_LINE1: string = 'We Will Launch Soon';
    readonly COMING_SOON_MESSAGE_LINE2: string = 'Stay tuned';
    readonly COMING_SOON_MESSAGE_LINE3: string = `About Us page is under construction. 
        You may be able to return to the site's home page and see if 
        you can find what you are looking for.`;
    ICON = LCE_WEB_ICON;
    constructor() {}

    ngOnInit(): void {}
}
