import { Component, Input } from '@angular/core';

@Component({ selector: 'lce-web-section-c-item', templateUrl: './lce-web-section-c-item.component.html' })
export class LCEWebSectionCItemComponent {
    @Input() styleClass: string;

    @Input() title: string;

    @Input() description: string;

    @Input() imageURL: string;
}
