import {Component, Input, OnInit} from '@angular/core';
import {XSUtils} from '@xs/base';
import {LCE_WEB_ICON} from '@lce-web/core/constants/lce-web-icon.constant';

@Component({
    selector: 'lce-web-coming-soon-temp',
    templateUrl: './lce-web-coming-soon-temp.html',
    styleUrls: ['./lce-web-coming-soon-temp.scss']
})
export class LCEWebComingSoonComponentTemp implements OnInit {

    readonly ICON = LCE_WEB_ICON;

    @Input() styleClass?: string;
    @Input() line1StyleClass?: string;
    @Input() line2StyleClass?: string;
    @Input() line3StyleClass?: string;
    @Input() imageStyleClass?: string;
    @Input() iconStyleClass?: string;

    @Input() iconFontSize?: string;
    @Input() icon?: string;

    @Input() line1: string;
    @Input() line2?: string;
    @Input() line3?: string;

    @Input() image: string;

    imageIsEmpty: boolean;

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.line1)) this.line1 = 'COMING SOON!';
        if (XSUtils.isEmpty(this.image)) this.imageIsEmpty = true;
    }
}
