import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LCEWebFeaturesModule} from '@lce-web/features/lce-web-features.module';
import {LCEWebContactPageComponent} from '@lce-web/features/contact/lce-web-contact-page.component';
import {LCEWebLandingPageComponent} from '@lce-web/features/landing-page/lce-web-landing-page.component';
import {LCEWebTemplateDefaultPageComponent} from './templates/default/lce-web-template-default.component';
import {LCEWebTemplatesModule} from './templates/lce-web-template.module';
import {LCEWebTeamComponent} from './features/team/lce-web-team.component';
import {LCEWebHowWorkComponent} from './features/how-work/lce-web-how-work.component';
import {LCEWebAboutUsComponent} from './features/about-us/lce-web-about-us.component';
import {LCEWebCertificateOrderProcessComponent} from './features/certificate-order-process/lce-web-certificate-order-process.component';
import {LCEWebServicesComponent} from '@lce-web/features/services/lce-web-services.component';
import {LCENewsArticleDetailComponent, LCESmartCityArticleViewComponent} from '@lce/shared';
import {LCEWebSmartCityComponent} from '@lce-web/features/smartcity/lce-web-smartcity.component';
import {LCEWebNewsComponent} from '@lce-web/features/news/lce-web-news.component';

const routes: Routes = [
    {
        path: '',
        component: LCEWebTemplateDefaultPageComponent,
        children: [
            {path: '', component: LCEWebLandingPageComponent},
            {path: 'contact', component: LCEWebContactPageComponent},
            {path: 'team', component: LCEWebTeamComponent},
            {path: 'how-work', component: LCEWebHowWorkComponent},
            {path: 'about-us', component: LCEWebAboutUsComponent},
            {path: 'certificate-order', component: LCEWebCertificateOrderProcessComponent},
            {path: 'services', component: LCEWebServicesComponent},
            {path: 'smartcity/articles', component: LCEWebSmartCityComponent},
            {path: 'smartcity/articles/:code', component: LCESmartCityArticleViewComponent},
            {path: 'news/articles', component: LCEWebNewsComponent},
            {path: 'news/articles/:code', component: LCENewsArticleDetailComponent}
        ]
    },
    {
        path: 'contact',
        component: LCEWebContactPageComponent
    },
    {path: '**', redirectTo: 'not-found'},
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}), LCEWebFeaturesModule, LCEWebTemplatesModule],
    exports: [RouterModule]
})
export class LCEWebRoutingModule {
}
