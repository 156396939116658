import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {XSCoreModule} from '@xs/core';
import {LCESharedModule} from '@lce/shared';

import {LCEWebHeaderComponent} from '@lce-web/shared/header/lce-web-header.component';
import {LCEWebTranslationButtonComponent} from '@lce-web/shared/lce-web-translation-button.component';
import {LCEWebFooterComponent} from './footer/lce-web-footer.component';
import {LCEWebAddressComponent} from './lce-address/lce-web-address.component';
import {LCEWebComingSoonComponent} from './coming-soon/xs-coming-soon.component';
import {LCEWebComingSoonComponentTemp} from './coming-soon-temp/lce-web-coming-soon-temp';

@NgModule({
    imports: [
        // ***
        CommonModule,
        XSCoreModule,
        LCESharedModule
    ],
    declarations: [
        // ***
        LCEWebHeaderComponent,
        LCEWebTranslationButtonComponent,
        LCEWebFooterComponent,
        LCEWebAddressComponent,
        LCEWebComingSoonComponent,
        LCEWebComingSoonComponentTemp
    ],
    exports: [
        // ***
        XSCoreModule,
        LCESharedModule,
        LCEWebHeaderComponent,
        LCEWebTranslationButtonComponent,
        LCEWebFooterComponent,
        LCEWebAddressComponent,
        LCEWebComingSoonComponent,
        LCEWebComingSoonComponentTemp
    ]
})
export class LCEWebSharedModule {
}
