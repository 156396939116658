<div>
    <div class="xs-font-size-default {{ styleClass }}">
        <span routerLink="/" class="cursor-pointer lce-web-app-primary-color xs-font-weight-300 xs-font-size-20 xs-display-block xs-font-size-default xs-mb-5"
            ><b>L</b>a <b>C</b>omnune <b>É</b>lectronique</span
        >
        <div class="xs-line-height-1-5">
            <span [innerHTML]="'lce.website.LCEAddress.address1' | translate" class="xs-display-block xs-font-size-percent-95"></span>
            <span [innerHTML]="'lce.website.LCEAddress.address2' | translate" class="xs-display-block xs-font-size-percent-95"></span>
            <span [innerHTML]="'lce.website.LCEAddress.address3' | translate" class="xs-display-block xs-font-size-percent-85"></span>
        </div>
    </div>
</div>
