import {CommonModule, LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import localeFR from '@angular/common/locales/fr';
import {APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {LCEWebRoutingModule} from '@lce-web/app/lce-web-routing.module';
import {LCEWebComponent} from '@lce-web/app/lce-web.component';
import {LCE_WEB_ENDPOINT} from '@lce-web/core/constants/lce-web-endpoint.constant';
import {LCE_WEB_ICON} from '@lce-web/core/constants/lce-web-icon.constant';
import {LCEWebErrorHandler} from '@lce-web/core/handlers/lce-web-error-handler';
import {LCEWebErrorHelper} from '@lce-web/core/helpers/lce-web-error.helper';
import {LCEWebHttpInterceptor} from '@lce-web/core/interceptors/lce-web-http-interceptor';
import {LCEWebAppInitializerService} from '@lce-web/core/services/lce-web-app-initializer.service';
import {LCEWebContextService} from '@lce-web/core/services/lce-web-context.service';
import {LCEWebEnvironmentService} from '@lce-web/core/services/lce-web-environment.service';
import {LCEWebErrorService} from '@lce-web/core/services/lce-web-error.service';
import {environment} from '@lce-web/environments/environment';
import {ADDITIONAL_IMPORTS, ADDITIONAL_PROVIDERS} from '@lce-web/environments/environment-common';
import {LCECoreContextService, LCECoreModule, LCEEnvironmentService, LCEHttpClientService} from '@lce/core';
import {LCESharedContextService} from '@lce/shared';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {XSAppInitializerBaseService, XSBaseContextService, XSBaseEnvironmentService, XSBaseModule, XSHttpClientService, XSLoggerService, XSStorageService} from '@xs/base';
import {XS_TOKEN_COMMON_LOADER_DEFAULT_TYPE, XSCommonContextService, XSCommonEnvironmentService, XSCommonModule, XSCountryService, XSLoaderType} from '@xs/common';
import {
    XS_TOKEN_CORE_ENDPOINT_GEOLOCATE,
    XS_TOKEN_CORE_ICON,
    XSAppInitializerCoreService,
    XSCoreContextService,
    XSCoreEnvironmentService,
    XSCoreModule,
    XSEnsureModuleImportedOnce,
    XSErrorHelper,
    XSErrorService,
    XSLocalStorageService
} from '@xs/core';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {XS_PAYMENT_TOKEN_ENDPOINT_BASE} from '@xs/payment/base';

// ------------------------------------------------------------------------------------------------------
// ---------------------------------------- Translation -------------------------------------------------
// ------------------------------------------------------------------------------------------------------

registerLocaleData(localeFR);

export function HttpLoaderFactory(httpBackend: HttpBackend) {
    const version = Date.now(); // Workaround to force the browser to take the new version of translation files if needed.
    return new MultiTranslateHttpLoader(new HttpClient(httpBackend), [
        {prefix: './assets/i18n/xs-primeng/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-common/', suffix: '.json?' + version},
        {prefix: './assets/i18n/xs-core/', suffix: '.json?' + version},
        {prefix: './assets/i18n/xs-country/', suffix: '.json?' + version},
        {prefix: './assets/i18n/xs-payment-base/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-payment-checkout/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/lce-core/', suffix: '.json?' + version},
        {prefix: './assets/i18n/lce-shared/', suffix: '.json?' + version},
        {prefix: './assets/i18n/lce-web/', suffix: '.json?' + version}
    ]);
}

// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------

@NgModule({
    declarations: [LCEWebComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.common.defaultLanguage,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        LoggerModule.forRoot({level: NgxLoggerLevel.TRACE, timestampFormat: XSLoggerService.TIMESTAMP_FORMAT}),
        XSBaseModule,
        XSCommonModule,
        XSCoreModule,
        LCECoreModule,
        LCEWebRoutingModule,
        ADDITIONAL_IMPORTS
    ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        // --- * ---
        ADDITIONAL_PROVIDERS,
        // --- Initializers ---
        {provide: XSAppInitializerBaseService, useExisting: LCEWebAppInitializerService},
        {provide: XSAppInitializerCoreService, useExisting: LCEWebAppInitializerService},
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializerService: LCEWebAppInitializerService) => () => appInitializerService.initialize(),
            deps: [LCEWebAppInitializerService],
            multi: true // means we could have multiple APP_INITIALIZER.
        },
        // --- Interceptors ---
        {provide: HTTP_INTERCEPTORS, useClass: LCEWebHttpInterceptor, multi: true},

        // --- Services ---
        {provide: XSHttpClientService, useExisting: LCEHttpClientService},

        {provide: XSErrorHelper, useExisting: LCEWebErrorHelper},
        {provide: ErrorHandler, useExisting: LCEWebErrorHandler},
        {provide: XSErrorService, useExisting: LCEWebErrorService},

        {provide: XSBaseEnvironmentService, useExisting: LCEWebEnvironmentService},
        {provide: XSCommonEnvironmentService, useExisting: LCEWebEnvironmentService},
        {provide: XSCoreEnvironmentService, useExisting: LCEWebEnvironmentService},
        {provide: LCEEnvironmentService, useExisting: LCEWebEnvironmentService},

        {provide: XSBaseContextService, useExisting: LCEWebContextService},
        {provide: XSCommonContextService, useExisting: LCEWebContextService},
        {provide: XSCoreContextService, useExisting: LCEWebContextService},
        {provide: LCECoreContextService, useExisting: LCEWebContextService},
        {provide: LCESharedContextService, useExisting: LCEWebContextService},

        {provide: XSStorageService, useExisting: XSLocalStorageService},

        // --- * ---
        XSCountryService,

        // --- Tokens ---

        {provide: XS_PAYMENT_TOKEN_ENDPOINT_BASE, useValue: 'payment'},
        {provide: XS_TOKEN_CORE_ENDPOINT_GEOLOCATE, useValue: LCE_WEB_ENDPOINT.geolocate},
        {provide: XS_TOKEN_CORE_ICON, useValue: LCE_WEB_ICON},
        {provide: XS_TOKEN_COMMON_LOADER_DEFAULT_TYPE, useValue: XSLoaderType.RECTANGLE_BOUNCE}
    ],
    bootstrap: [LCEWebComponent]
})
export class LCEWebModule extends XSEnsureModuleImportedOnce {
    public constructor(@SkipSelf() @Optional() module: LCEWebModule) {
        super(module);
    }
}
