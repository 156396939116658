import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCENewsArticleCard} from '@lce/core';
import {XSAssert, XSLanguage} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR, XS_DATE_FORMAT_YYYY_MM_DD_HH_MM, XSTranslationService} from '@xs/common';

@Component({selector: 'lce-news-article-card-web', templateUrl: 'lce-news-article-card-web.component.html', styles: [':host { width: fit-content; }']})
export class LCENewsArticleCardWebComponent implements OnInit {
    ICON = LCE_SHARED_ICON;

    readonly DATE_FORMAT = XS_DATE_FORMAT_YYYY_MM_DD_HH_MM;

    @Input() styleClass?: string;
    @Input() disabled?: boolean;

    @Input() data: LCENewsArticleCard;

    constructor(private router: Router, private translationService: XSTranslationService) {
    }

    get createdOnDateFormat(): string {
        return this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN : XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    public isClickable(): boolean {
        return this.disabled !== true;
    }

    public onCardClick(event: Event): void {
        if (this.disabled === true) {
            event.stopImmediatePropagation();
            return;
        }
        this.router.navigate(['/news/articles/code', this.data.code],
            {
                state: {
                    options: {
                        showAudit: false
                    }

                }
            })
            .then();
    }
}
