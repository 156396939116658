<div class="xs-flex-column-center xs-width-full xs-height-full {{ styleClass }}">
    <div class="lce-news-main-container">
        <!-- === Search & Filters === -->
        <div class="xs-width-full xs-flex-column-center xs-mb-15">
            <div class="xs-field xs-field-input xs-field-input-search xs-max-width-600">
                <label class="xs-font-size-20" for="searchText">{{ TR_BASE + 'searchLabel' | translate }}</label>
                <xs-input-search
                        (changeEvent)="onInputSearchChange()"
                        [(value)]="searchText"
                        [delay]="SEARCH_TEXT_DELAY"
                        [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                        [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                        [placeholder]="TR_BASE + 'searchPlaceholder'"
                        [searching]="isSecondaryLoaderRunning()"
                        inputId="searchText">
                </xs-input-search>
            </div>
            <div class="xs-sm-display-none xs-flex-column-center xs-width-full xs-mt-30">
                chips
            </div>
        </div>

        <!-- === Listing === -->
        <div class="xs-card xs-flex-1">
            <div class="xs-card-content xs-align-items-center xs-justify-content-start xs-min-height-200">
                <div class="xs-absolute-center-vh">
                    <xs-loader #primaryLoader [loaderId]="PRIMARY_LOADER_ID" [loaderSize]="30"></xs-loader>
                </div>
                <div *ngIf="hasError() && !primaryLoader.isRunning() && firstInitialization" class="xs-absolute-center-vh">
                    <xs-error
                            [actionButton]="errorRetryButton"
                            [error]="error"
                            [showActionButton]="true"
                            [showErrorDetailButton]="true"
                            errorDetailButtonClass="xs-mt-5-imp"
                            message="lce.shared.news.label.errorMessageSearchArticles"
                            mode="block"
                            subMessage="xs.core.error.contactAdminMessage">
                    </xs-error>
                </div>
                <xs-no-result-found *ngIf="(articles | xsIsEmpty) && !primaryLoader.isRunning() && !hasError()" size="large" styleClass="xs-absolute-center-vh">
                </xs-no-result-found>
                <div
                        *ngIf="(articles | xsIsNotEmpty) && !primaryLoader.isRunning()"
                        [ngClass]="{ 'xs-disabled': isSecondaryLoaderRunning() }"
                        class="grid xs-width-full">
                    <div *ngFor="let article of articles" class="col-12 md:col-6 lg:col-4 xs-flex-row-center xs-justify-content-center xs-p-15">
                        <lce-news-article-card-web [data]="article"></lce-news-article-card-web>
                    </div>
                </div>
            </div>

            <!-- === Paginator === -->
            <div class="xs-card-footer">
                <div class="xs-flex-row xs-justify-content-center xs-width-full">
                    <xs-paginator-load-more
                            (errorEvent)="handlePaginationError($event)"
                            (loadEvent)="handlePaginationLoad($event)"
                            [options]="paginationOptions"
                            [paginationPage]="pagination.page"
                            [paginationSize]="pagination.size"
                            [showBorderTop]="true"
                            [total]="nArticles"
                            class="xs-width-full xs-flex-row-center xs-position-absolute xs-bottom-0 xs-plr-15 xs-background-default" styleClass="xs-flex-row-center xs-width-full">
                    </xs-paginator-load-more>
                </div>
            </div>
        </div>
    </div>
</div>
