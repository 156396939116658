import {Injectable} from '@angular/core';
import {XSDialogService, XSErrorService} from '@xs/core';
import {LCEWebEnvironmentService} from './lce-web-environment.service';

@Injectable({providedIn: 'root'})
export class LCEWebErrorService extends XSErrorService {
    constructor(protected environmentService: LCEWebEnvironmentService, protected dialogService: XSDialogService) {
        super(environmentService, dialogService);
    }
}
