import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSUtils} from '@xs/base';
import {XSDeviceDetectorService, XSDeviceInformations} from '@xs/core';

@Component({selector: 'lce-download-app-buttons', templateUrl: './lce-download-app-buttons.component.html'})
export class LCEDownloadAppButtonsComponent implements OnInit {
    @Input() styleClass?: string;
    @Input() buttonsStyleFlexPosition?: string;

    @Input() text?: string;

    @Input() imgWidth?: number;

    isMobile: boolean;
    userDeviceInfo: XSDeviceInformations;

    @Output() downloadAppClickEvent = new EventEmitter();
    @Output() appStoreClickEvent = new EventEmitter();
    @Output() playStoreClickEvent = new EventEmitter();

    constructor(private deviceDetectorService: XSDeviceDetectorService) {
    }

    ngOnInit(): void {
        this.isMobile = this.deviceDetectorService.isMobile();

        if (XSUtils.isEmpty(this.text)) this.text = 'lce.shared.certificateOrders.process.useMobileVersion';

        this.userDeviceInfo = this.deviceDetectorService.getDeviceInfo();

        if (XSUtils.isNull(this.imgWidth)) this.imgWidth = 160;
    }
}
