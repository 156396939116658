<!--      [style.background]="getBackgroundColor()" -->
<div (click)="clickEvent.emit()"
     *ngIf="direction === 'horizontal'"
     [ngClass]="{ 'xs-disabled': disabled, 'xs-border-discrete': showBorder, 'xs-cursor-pointer': clickable }"
     [style.background-image]="getBackgroundImage()"
     [style.background-position]=" hasBackgroundImage() ? 'center' : ''"
     [style.background-repeat]="hasBackgroundImage() ? 'no-repeat' : ''"
     [style.background-size]="hasBackgroundImage() ? 'cover' : ''"
     class="xs-flex-row xs-align-items-center xs-width-full xs-border-radius-default xs-p-15 {{ styleClass }}"
     style="{{ (getBackgroundColor() | xsIsNotEmpty) ? ('background:' + getBackgroundColor()) : '' }}">

    <xs-icon-box
            [containerBackgroundColor]="iconContainerBackgroundColor"
            [containerStyleClass]="iconContainerStyleClass"
            [iconColor]="iconColor"
            [iconSize]="iconSize"
            [iconStyleClass]="iconValueStyleClass"
            [styleClass]="iconStyleClass"
            [value]="icon"
            class="xs-mr-20">
    </xs-icon-box>
    <div class="xs-flex-column xs-overflow-x-hidden">
        <span
                [style.color]="titleColor"
                class="xs-display-block xs-font-size-default xs-font-weight-600 xs-ellipsis {{titleStyleClass}}">
            {{ title | translate }}
        </span>
        <span
                *ngIf="subTitle | xsIsNotEmpty"
                [style.color]="subTitleColor"
                class="xs-display-block xs-font-size-small xs-color-primary xs-font-weight-500 xs-ellipsis {{subTitleStyleClass}}">
            {{ subTitle! | translate }}
        </span>
    </div>
</div>

<!-- ============================================================================================================================================ -->
<!-- === Vertical === -->
<!-- ============================================================================================================================================ -->

<div
        (click)="clickEvent.emit($event)"
        *ngIf="direction === 'vertical'"
        [ngClass]="{
     'xs-disabled': disabled,
     'xs-border-discrete': showBorder,
     'xs-cursor-pointer': clickable
     }"
        [style.background-image]="getBackgroundImage()"
        [style.background-position]=" hasBackgroundImage() ? 'center' : ''"
        [style.background-repeat]="hasBackgroundImage() ? 'no-repeat' : ''"
        [style.background-size]="hasBackgroundImage() ? 'cover' : ''"
        class="xs-flex-column xs-border-radius-default xs-width-full xs-p-15 {{styleClass}}"
        style="{{ (getBackgroundColor() | xsIsNotEmpty) ? ('background:' + getBackgroundColor()) : '' }}">


    <xs-icon-box
            [containerBackgroundColor]="iconContainerBackgroundColor"
            [containerStyleClass]="iconContainerStyleClass"
            [iconColor]="iconColor"
            [iconStyleClass]="iconValueStyleClass"
            [styleClass]="iconStyleClass"
            [value]="icon"
            class="xs-flex-row-center xs-mb-20">
    </xs-icon-box>
    <div class="xs-flex-column xs-overflow-x-hidden">
        <span
                [style.color]="titleColor"
                class="xs-display-block xs-font-size-default xs-font-weight-600  xs-ellipsis {{titleStyleClass}}">
            {{ title | translate }}
        </span>
        <span
                *ngIf="subTitle | xsIsNotEmpty"
                [style.color]="subTitleColor"
                class="xs-display-block xs-font-size-small xs-max-width-450 xs-font-weight-500 xs-ellipsis {{subTitleStyleClass}}">
            {{ subTitle! | translate }}
        </span>
    </div>
</div>