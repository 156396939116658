import { Component } from '@angular/core';
type Feature = {
    title: string;
    description: string;
    imageURL: string;
};
@Component({ selector: 'lce-web-section-c', templateUrl: './lce-web-section-c.component.html' })
export class LCEWebSectionCComponent {
    features: Feature[] = [
        {
            title: 'lce.website.features.feature0.title',
            description: `lce.website.features.feature0.description`,
            imageURL: `assets/images/feature-new-01.jpeg`,
        },
        {
            title: 'lce.website.features.feature1.title',
            description: `lce.website.features.feature1.description`,
            imageURL: `assets/images/feature-new-02.jpeg`,
        },
        {
            title: 'lce.website.features.feature2.title',
            description: `lce.website.features.feature2.description`,
            imageURL: `assets/images/feature-new-01.jpeg`,
        },
    ];
}
