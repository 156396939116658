import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {XSDeviceDetectorService} from '@xs/core';
import {LCEWebContextService} from '@lce-web/services/lce-web-context.service';
import {XSEnvironmentName} from '@xs/base';

@Component({selector: 'lce-web-certificate-order-process', templateUrl: './lce-web-certificate-order-process.component.html'})
export class LCEWebCertificateOrderProcessComponent implements OnInit {

    @Input() styleClass: string;

    isMobile: boolean;

    constructor(
        private router: Router,
        private deviceDetectorService: XSDeviceDetectorService,
        private contextService: LCEWebContextService) {
    }

    ngOnInit(): void {
        this.isMobile = this.deviceDetectorService.isMobile();
    }

    public isNoProd(): boolean {
        return this.contextService.getAppConfig().environment !== XSEnvironmentName.PROD;
    }

    public handleCancelEvent(): void {
        this.router.navigateByUrl('/').then();
    }

    public continueWithWebVersion(): void {
        this.isMobile = false;
        this.router.navigateByUrl('/certificate-order').then();
    }
}
