import {Component} from '@angular/core';
import {LCE_MBO_ICON} from '@lce-mbo/core/constants/lce-mbo-icon.constant';
import {LCENewsFeatureService} from '@lce/shared';

@Component({
    selector: 'lce-web-news',
    templateUrl: 'lce-web-news.component.html',
    host: {class: 'xs-flex-column xs-flex-1'}
})
export class LCEWebNewsComponent {

    readonly ICON = LCE_MBO_ICON;

    constructor(public featureService: LCENewsFeatureService) {
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }

}
