import {Component, Input, OnInit} from '@angular/core';
import {XS_LOREM_IPSUM} from '@xs/base';
import {LCE_WEB_ICON} from '@lce-web/core/constants/lce-web-icon.constant';
import {Router} from '@angular/router';

@Component({selector: 'lce-web-services', templateUrl: './lce-web-services.component.html'})
export class LCEWebServicesComponent implements OnInit {

    readonly ICON = LCE_WEB_ICON;
    readonly LOREM_IPSUM = XS_LOREM_IPSUM;
    readonly DEFAULT_BACKGROUND_IMAGE = 'assets/images/services/pont-cocody.jpeg';
    readonly SMART_CITY_IMAGE = 'assets/images/services/smartCity.jpg';

    @Input() styleClass?: string;

    @Input() scannerType: 'global' | 'specific';

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    public onServiceClick(service: 'certificate' | 'information' | 'news' | 'smartCity'): void {
        switch (service) {
            case 'certificate':
                this.router.navigateByUrl('/certificate-order');
                break;
            case 'information':
                console.log('Information service clicked');
                break;
            case 'news':
                this.router.navigateByUrl('/news/articles');
                break;
            case 'smartCity':
                this.router.navigateByUrl('/smartcity/articles');
                break;
        }


    }

    public backToHomePage(): void {
        this.router.navigateByUrl('/');
    }
}