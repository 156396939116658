import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LCEWebModule } from '@lce-web/app/lce-web.module';
import { environment } from '@lce-web/environments/environment';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(LCEWebModule)
	.catch((err) => console.error(err));
