<div class="xs-width-full {{styleClass}}">
    <div class="grid grid-nogutter">
        <div *ngIf="!imageIsEmpty" class="col-12 md:col-6">
            <img [src]="image" alt="" class="{{imageStyleClass}} xs-width-full xs-height-full" srcset=""/>
        </div>
        <div [ngClass]="{ 'md:col-12' : imageIsEmpty}" class="col-12 md:col-6">
            <div class="xs-flex-column xs-ptb-40 xs-plr-20 xs-align-items-center xs-justify-content-center xs-text-align-center xs-height-full">
                <i [style.font-size]="iconFontSize" class="xs-display-block xs-mb-15 xs-color-discrete {{ ICON.comingSoon }} {{ iconStyleClass }}"></i>
                <span *ngIf="line2" [style.letter-spacing]="'5px'" class="xs-display-block line2 xs-font-size-16 xs-text-transform-uppercase xs-font-weight-400 xs-mb-15 {{line2StyleClass}}">
                    {{ line2 }}</span
                >
                <span class="xs-display-block lce-web-app-primary-color xs-font-size-40 xs-font-weight-bold {{line1StyleClass}}">{{ line1 }}</span>
                <span *ngIf="line3" class="xs-display-block xs-font-weight-300 xs-font-size-15 xs-mt-5  {{line3StyleClass}}">{{ line3 }}</span>
            </div>
        </div>
    </div>
</div>
