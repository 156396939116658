<div class="xs-flex-column">
    <div class="xs-flex-column">
        <span class="xs-display-block xs-font-size-large xs-font-weight-400">{{ featureService.state.title | translate }}</span>
        <span *ngIf="featureService.state.subTitle | xsIsNotEmpty" class="xs-display-block xs-font-size-small xs-mt-1">
                {{ featureService.state.subTitle! | translate }}
            </span>
    </div>
    <div class="xs-pt-25-imp">
        <lce-smartcity-articles-web></lce-smartcity-articles-web>
    </div>
</div>