<div class="xs-width-full xs-flex-column {{styleClass}}">

    <div class="xs-flex-row xs-justify-content-space-between xs-mb-20">
        <span class="xs-display-block xs-color-primary xs-font-size-medium">{{ 'lce.website.services.title' | translate }}</span>
        <xs-icon (clickEvent)="backToHomePage()" [value]="ICON.home" size="large"></xs-icon>
    </div>

    <div class="xs-flex-column xs-align-items-center">
        <xs-feature-item
                (clickEvent)="onServiceClick('certificate')"
                [backgroundImage]="DEFAULT_BACKGROUND_IMAGE"
                [icon]="ICON.order"
                [showBorder]="true"
                [subTitle]="LOREM_IPSUM.medium"
                direction="vertical"
                iconColor="white"
                iconContainerBackgroundColor="#e17b31"
                styleClass="xs-max-width-450 xs-mb-30"
                title="Demande Acte"
                titleColor="#e17b31">
        </xs-feature-item>
        <xs-feature-item
                (clickEvent)="onServiceClick('information')"
                [backgroundImage]="DEFAULT_BACKGROUND_IMAGE"
                [icon]="ICON.info"
                [showBorder]="true"
                [subTitle]="LOREM_IPSUM.medium"
                direction="vertical"
                iconColor="white"
                iconContainerBackgroundColor="#e17b31"
                styleClass="xs-max-width-450 xs-mb-30"
                title="Information"
                titleColor="#e17b31">
        </xs-feature-item>
        <xs-feature-item
                (clickEvent)="onServiceClick('news')"
                [backgroundImage]="SMART_CITY_IMAGE"
                [icon]="ICON.news"
                [showBorder]="true"
                [subTitle]="LOREM_IPSUM.medium"
                direction="vertical"
                iconColor="white"
                iconContainerBackgroundColor="#e17b31"
                styleClass="xs-max-width-450 xs-mb-30"
                title="Actualité"
                titleColor="#e17b31">
        </xs-feature-item>
        <xs-feature-item
                (clickEvent)="onServiceClick('smartCity')"
                [backgroundImage]="SMART_CITY_IMAGE"
                [icon]="ICON.smartCity"
                [showBorder]="true"
                [subTitle]="LOREM_IPSUM.medium"
                direction="vertical"
                iconColor="white"
                iconContainerBackgroundColor="#e17b31"
                styleClass="xs-max-width-450 xs-mb-30"
                title="SmartCity"
                titleColor="#e17b31">
        </xs-feature-item>
    </div>
</div>