<div class="xs-width-full {{ styleClass }} lce-web-certificate-order-process-page xs-p-15 xs-height-full">
    <lce-use-mobile-version (continueWithWebVersionEvent)="continueWithWebVersion()" *ngIf="isMobile"></lce-use-mobile-version>
    <div *ngIf="!isMobile">
        <lce-certificate-order-process
                (cancelEvent)="handleCancelEvent()"
                [options]="{
                countryISO: 'ci',
                useProcuration: true,
                showFillFormButton: isNoProd()
                }">
        </lce-certificate-order-process>
    </div>
</div>
