import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lce-web-how-work',
    templateUrl: './lce-web-how-work.component.html',
})
export class LCEWebHowWorkComponent implements OnInit {
    readonly COMING_SOON_IMAGEURL: string = 'assets/images/cat.jpg';
    readonly Coming_SOON_MESSAGE_LINE1: string = 'We Will Launch Soon';
    readonly Coming_SOON_MESSAGE_LINE2: string = 'Stay turned';
    readonly Coming_SOON_MESSAGE_LINE3: string = `How Work page is under construction. 
        You may be able to return to the site's home page and see if 
        you can find what you are looking for.`;

    constructor() {}

    ngOnInit(): void {}
}
