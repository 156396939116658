import { Component, Input } from '@angular/core';
import { LCE_WEB_ICON } from '@lce-web/core/constants/lce-web-icon.constant';

@Component({ selector: 'lce-web-footer', templateUrl: './lce-web-footer.component.html' })
export class LCEWebFooterComponent {
    ICON = LCE_WEB_ICON;

    @Input() styleClass: string;

    @Input() showContactUsButton: boolean;

    @Input() footerText: string;

    currentYear: number;
    constructor() {
        this.currentYear = new Date().getFullYear();
    }
}
