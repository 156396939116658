import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LCEWebSharedModule } from '@lce-web/shared/lce-web-shared.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LCEWebTemplateDefaultPageComponent } from './default/lce-web-template-default.component';

@NgModule({
    imports: [
        // ***
        CommonModule,
        LCEWebSharedModule,
        InputTextareaModule,
    ],
    declarations: [
        // ***
        LCEWebTemplateDefaultPageComponent,
    ],
    exports: [
        // ***
    ],
})
export class LCEWebTemplatesModule {}
