<div [ngClass]="{'xs-border-discrete xs-border-radius-default xs-p-10': showBorder}" class=" xs-mb-15">
    <div class="xs-flex-row-center">
        <lce-download-app-buttons
                buttonsStyleFlexPosition="xs-flex-row-center"
                styleClass="xs-mb-10 xs-flex-column-center">
        </lce-download-app-buttons>
    </div>
    <small
            (click)="continueWithWebVersion()"
            [innerHTML]="'lce.shared.certificateOrders.process.continueWithWebVersion' | translate"
            class="xs-display-block xs-text-align-center lce-web-app-primary-color xs-mb-15"></small>
</div>