import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
@Component({ selector: 'lce-web-section-b-item', templateUrl: './lce-web-section-b-item.component.html' })
export class LCEWebSectionBComponentItem implements OnDestroy {
    @Input() styleClass: string;

    @Input() number: number;
    @Input() tilte: string;
    @Input() description: string;

    private subscription: Subscription = new Subscription();

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
