<div class="lce-web-section lce-web-section-a">
    <div class="grid">
        <div class="col-12 lg:col-6 xs-flex-row">
            <div class="xs-flex-column-center xs-plr-30 xs-text-align-center">
                <h2 [innerHTML]="'lce.website.banner.title' | translate" class="landing-page-title"></h2>
                <p [innerHTML]="'lce.website.banner.description' | translate" class="xs-font-size-14 xs-line-height-1-5 xs-font-weight-400 xs-text-align-center xs-mb-15"></p>
                <xs-button
                        label="lce.website.banner.orderButton"
                        routerLink="/certificate-order"
                        styleClass="xs-min-width-400-imp xs-border-radius-8-imp xs-width-full"
                        type="button"></xs-button>

            </div>
        </div>
        <div class="col-12 lg:col-6 xs-flex-row xs-justify-content-center">
            <img alt="" src="assets/images/mobile.png" srcset="" width="300"/>
        </div>
    </div>
</div>
