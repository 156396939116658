import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XSFormUtils} from '@xs/common';
import {XSInputFieldTextOptions} from '@xs/core';

@Component({selector: 'lce-web-contact-page', templateUrl: './lce-web-contact-page.component.html'})
export class LCEWebContactPageComponent implements OnInit {
    fieldsFormGroup: FormGroup = new FormGroup({});

    firstNameFieldOptions: XSInputFieldTextOptions;
    lastNameFiledOptions: XSInputFieldTextOptions;
    emailFieldOptions: XSInputFieldTextOptions;
    messageFieldOptions: XSInputFieldTextOptions;
    phoneNumberFieldOptions: XSInputFieldTextOptions;

    ngOnInit(): void {
        this.buildFields();
    }

    public buildFields(): void {
        this.firstNameFieldOptions = {
            fieldName: 'firstName',
            label: 'xs.common.label.firstName',
            control: new FormControl('', [Validators.required])
        };

        this.lastNameFiledOptions = {
            fieldName: 'lastName',
            label: 'xs.common.label.lastName',
            control: new FormControl('', [Validators.required])
        };

        this.phoneNumberFieldOptions = {
            fieldName: 'phoneNumber',
            label: 'xs.core.label.phoneNumber',
            control: new FormControl('', [Validators.required])
        };

        this.emailFieldOptions = {
            fieldName: 'email',
            label: 'xs.core.label.email',
            control: new FormControl('', [Validators.required, Validators.email])
        };

        this.messageFieldOptions = {
            fieldName: 'message',
            label: 'lce.website.contactPage.message',
            control: new FormControl('', [Validators.required])
        };
        this.fieldsFormGroup.addControl(this.firstNameFieldOptions.fieldName, this.firstNameFieldOptions.control!);
        this.fieldsFormGroup.addControl(this.lastNameFiledOptions.fieldName, this.lastNameFiledOptions.control!);
        this.fieldsFormGroup.addControl(this.phoneNumberFieldOptions.fieldName, this.phoneNumberFieldOptions.control!);
        this.fieldsFormGroup.addControl(this.emailFieldOptions.fieldName, this.emailFieldOptions.control!);
        this.fieldsFormGroup.addControl(this.messageFieldOptions.fieldName, this.messageFieldOptions.control!);
    }

    public onGetFormData(): void {
        XSFormUtils.validateFormGroup(this.fieldsFormGroup);
        if (this.fieldsFormGroup.valid) {
            const formData = this.fieldsFormGroup.getRawValue();
        } else {
            const formErrors = XSFormUtils.getFormErrors(this.fieldsFormGroup);
        }
    }
}
