<div class="lce-web-section-c-item grid xs-flex xs-align-items-center xs-mt-20 xs-font-weight-300">
    <div class="col-12 md:col-6 xs-plr-30-imp">
        <div class="xs-flex-column xs-justify-content-center xs-align-items-center xs-text-align-center">
            <h2 [innerHTML]="title | translate"></h2>
            <p class="lce-landing-page-text" [innerHTML]="description | translate"></p>
        </div>
    </div>
    <div class="col-12 md:col-6 xs-flex xs-justify-content-center">
        <img [src]="imageURL" alt="" srcset="" />
    </div>
</div>
