<div class="lce-web-contact-page">
    <div class="lce-web-contact-page-content">
        <div>
            <div class="grid xs-mb-35">
                <div class="section1 xs-plr-30 col-12 lg:col-6 xs-flex-column">
                    <div class="xs-flex-colunm">
                        <h2 [innerHTML]="'lce.website.contactPage.title' | translate" class="contact-page-title xs-mt-0 xs-mb-0 xs-text-align-left"></h2>
                        <span [innerHTML]="'lce.website.contactPage.subTitle' | translate" class="xs-display-block contact-page-subtitle xs-font-size-13 xs-text-align-left"></span>
                    </div>
                    <form class="xs-mt-50">
                        <div class="grid">
                            <div class="col-12 md:col-6">
                                <xs-input-field-text [options]="firstNameFieldOptions"></xs-input-field-text>
                            </div>
                            <div class="col-12 md:col-6">
                                <xs-input-field-text [options]="lastNameFiledOptions"></xs-input-field-text>
                            </div>
                            <div class="col-12">
                                <xs-input-field-text [options]="phoneNumberFieldOptions"></xs-input-field-text>
                            </div>
                            <div class="col-12 xs-mb-10">
                                <xs-input-field-text [options]="emailFieldOptions"></xs-input-field-text>
                            </div>
                            <div class="col-12 xs-mb-15">
                                <xs-input-field-textarea [options]="messageFieldOptions" maxLength="500"></xs-input-field-textarea>
                            </div>

                            <div class="col-12">
                                <xs-button
                                        (clickEvent)="onGetFormData()"
                                        class="xs-width-full"
                                        label="lce.website.contactPage.sendMessageButton"
                                        styleClass="lce-web-app-primary-background-color xs-width-full xs-height-35-imp lce-web-mb-sm-40 lce-web-mb-md-40 xs-min-width-160"
                                        type="button">
                                </xs-button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="section2 col-12 lg:col-6 xs-flex-row lce-web-pl-sm-0-imp lce-web-pl-md-0-imp">
                    <img alt="" class="xs-width-full xs-border-radius-default" src="assets/images/iro-location.png" srcset="" width="300"/>
                </div>
            </div>
        </div>
    </div>
</div>
