<div class="lce-web-footer">
    <lce-web-translation-button class="xs-position-absolute xs-right-15 xs-top-15"></lce-web-translation-button>

    <div class="grid xs-mb-15">
        <div class="col-12 md:col-4 xs-pt-40">
            <lce-download-app-buttons text="lce.website.label.useMobileVersion"></lce-download-app-buttons>
            <div class="social-icons xs-mt-30">
                <i class="{{ ICON.instagram }}"></i>
                <i class="{{ ICON.facebook }} xs-mlr-20"></i>
                <i class="{{ ICON.twitter }}"></i>
            </div>
        </div>
        <div class="col-12 md:col-3 xs-text-align-left xs-pt-40">
            <ul>
                <li [innerHTML]="'lce.website.footer.aboutLCE' | translate" class="xs-font-size-default xs-cursor-pointer" routerLink="/about-us" routerLinkActive="active-link"></li>
                <li [innerHTML]="'lce.website.footer.howWork' | translate" class="xs-font-size-default xs-cursor-pointer" routerLink="/how-work" routerLinkActive="active-link"></li>
                <li [innerHTML]="'lce.website.footer.ourTeam' | translate" class="xs-font-size-default xs-cursor-pointer" routerLink="/team" routerLinkActive="active-link"></li>
                <li
                        *ngIf="showContactUsButton"
                        [innerHTML]="'lce.website.footer.contactUs' | translate"
                        class="xs-font-size-default xs-cursor-pointer"
                        routerLink="/contact"
                        routerLinkActive="active-link"></li>
            </ul>
        </div>
        <div class="col-12 md:col-5 xs-pt-40">
            <div class="xs-flex-column">
                <lce-web-address styleClass="xs-mb-15"></lce-web-address>
            </div>
        </div>
    </div>
    <!-- TODO: xs-font-family-sans-serif must be added to xs-styles. -->
    <div [style.font-family]="'sans-serif'" class="xs-font-size-10 xs-font-weight-300 xs-text-align-center xs-font-fam">© {{ currentYear }} LCE v0.0.1 Powered by IRO-XS</div>
</div>
