import {Injectable} from '@angular/core';
import {LCEWebAppConfig} from '@lce-web/domain/lce-web-app-config';
import {XSGeolocationService} from '@xs/core';
import {LCESharedContextService} from '@lce/shared';

@Injectable({providedIn: 'root'})
export class LCEWebContextService extends LCESharedContextService {

    constructor(protected geolocationService: XSGeolocationService) {
        super(geolocationService);
    }

    // --- Config ----
    public getAppConfig(): LCEWebAppConfig {
        return super.getAppConfig() as LCEWebAppConfig;
    }

    public setAppConfig(appConfig: LCEWebAppConfig): void {
        super.setAppConfig(appConfig);
    }
}
