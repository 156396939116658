import {Injectable} from '@angular/core';
import {LCEWebAppConfig} from '@lce-web/core/domain/lce-web-app-config';
import {LCEWebContextService} from '@lce-web/services/lce-web-context.service';
import {LCEWebEnvironmentService} from '@lce-web/services/lce-web-environment.service';
import {LCEHttpClientService} from '@lce/core';
import {LOG, XS_STORAGE_KEY_LANGUAGE, XS_STORAGE_KEY_TOKEN, XSLanguage, XSLoggerService, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {XS_IMAGE_NOT_FOUND_SRC, XSAppInitializerCoreService, XSImageService, XSLocalStorageService} from '@xs/core';
import {PrimeNGConfig} from 'primeng/api';
import {LCEAppInitialDataWebsite} from '@lce-web/domain/lce-app-initial-data-website';
import {LCE_WEB_ENDPOINT} from '@lce-web/core/constants/lce-web-endpoint.constant';
import {HttpParams} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LCEWebAppInitializerService extends XSAppInitializerCoreService {


    constructor(
        protected primeNGConfig: PrimeNGConfig,
        protected translationService: XSTranslationService,
        private environmentService: LCEWebEnvironmentService,
        private httpClientService: LCEHttpClientService,
        private contextService: LCEWebContextService,
        private localStorageService: XSLocalStorageService,
        private loggerService: XSLoggerService,
        private imageService: XSImageService) {
        super(primeNGConfig, translationService);
    }

    public async initialize(): Promise<void> {
        LOG().debug('Initializing App ...');

        // Force the browser to get the latest version. Does not use the cache.
        const params = new HttpParams().set('v', XSUtils.uuid());
        const appConfig: LCEWebAppConfig = await this.httpClientService.get<LCEWebAppConfig>(this.CONFIG_JSON_PATH, params).toPromise();
        LOG().debug('[' + appConfig.environment.toUpperCase() + '] Config Successfully Retrieved.', appConfig);

        this.loggerService.initialize(appConfig.logger);
        this.contextService.setAppConfig(appConfig);
        this.configLoadedSubject.next(appConfig);

        const languageFromLocalStorage: XSLanguage = this.localStorageService.getItem(XS_STORAGE_KEY_LANGUAGE) as XSLanguage;
        let language: XSLanguage = XSUtils.isEmpty(languageFromLocalStorage) ? this.environmentService.getDefaultLanguage() : languageFromLocalStorage;

        await this.translationService.initialize(language);
        this.translationService.onLanguageChanged.subscribe((newLanguage) => this.localStorageService.setItem(XS_STORAGE_KEY_LANGUAGE, newLanguage.toString()));

        this.httpClientService.initialize(appConfig.apiBaseURL, language);

        const appInitialData: LCEAppInitialDataWebsite = await this.httpClientService.get<LCEAppInitialDataWebsite>(LCE_WEB_ENDPOINT.initialization).toPromise();
        LOG().debug('App Initialization Data Successfully Retrieved.', appInitialData);

        this.httpClientService.setHeaderAuthorization(appInitialData.token!);
        this.localStorageService.setItem(XS_STORAGE_KEY_TOKEN, appInitialData.token!);

        await this.imageService.cacheImage(XS_IMAGE_NOT_FOUND_SRC).toPromise();

        await this.configurePrimeNGTranslation();
    }
}
