<div class="{{ styleClass }} ">
    <span *ngIf="text" class="xs-display-block xs-font-weight-300 xs-font-size-14 xs-text-align-center"> {{ text | translate }}</span>
    <span *ngIf="!isMobile" [innerHTML]="'lce.website.downloadAppText' | translate" class="xs-display-block xs-color-primary xs-font-weight-500 xs-mtb-10 xs-font-size-16"></span>

    <xs-button (clickEvent)="downloadAppClickEvent.emit()" *ngIf="isMobile" label="lce.website.downloadAppText" size="intermediate" styleClass="xs-mtb-10" type="text"></xs-button>

    <div *ngIf="isMobile" class="xs-flex-wrap xs-width-full {{ buttonsStyleFlexPosition }}">
        <a *ngIf="userDeviceInfo.os == 'iOS'" href="" target="_blank">
            <img alt="App Store download button" class="xs-cursor-pointer" src="assets/images/app-store.png" width="{{ imgWidth }}"/>
        </a>
        <a *ngIf="userDeviceInfo.os == 'Android'" href="" target="_blank">
            <img alt="Google Play Store download button" class="xs-cursor-pointer" src="assets/images/google-play-store.png" width="{{ imgWidth }}"/>
        </a>
    </div>

    <div *ngIf="!isMobile" class="xs-flex-wrap xs-width-full {{ buttonsStyleFlexPosition }}">
        <a (click)="appStoreClickEvent.emit()" class="xs-mlr-10" target="_blank"> <img alt="App Store download button" class="xs-cursor-pointer" src="assets/images/app-store.png" width="{{ imgWidth }}"/></a>
        <a (click)="playStoreClickEvent.emit()" class="xs-mlr-10" href="" target="_blank"> <img alt="Google Play Store download button" class="xs-cursor-pointer" src="assets/images/google-play-store.png"
                                                                                                width="{{ imgWidth }}"/></a>
    </div>
</div>
