import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {XSChip} from '@iro-xs/xs-core';
import {LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE, LCEArticleReviewer, LCESmartCityArticle, LCESmartCityArticleService} from '@lce/core';
import {XSLanguage, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton, XSDialogable} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize, first} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESmartCityFeatureService} from '../lce-smartcity-feature.service';

@Component({selector: 'lce-smartcity-article-view', templateUrl: './lce-smartcity-article-view.component.html', host: {class: 'xs-flex-column xs-flex-1'}})
export class LCESmartCityArticleViewComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.smartcity.articleView.';

    readonly TR_BASE_TOOLTIP: string = 'lce.shared.smartcity.articleView.tooltip.';

    readonly TR_BASE_SMART_CITY_ARTICLE_TYPE: string = LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE;

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() smartCityArticle?: LCESmartCityArticle;
    @Input() smartCityArticleID?: string;

    @Output() editEvent = new EventEmitter<LCESmartCityArticle>();
    @Output() closeEvent = new EventEmitter<LCESmartCityArticle>();

    categoryChips: XSChip[] = [];
    tagChips: XSChip[] = [];
    reviewers: LCEArticleReviewer[] = [];

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveArticle()
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private smartCityService: LCESmartCityArticleService,
        private featureService: LCESmartCityFeatureService) {
        super();

        this.featureService.state.title = this.TR_BASE + 'title';
        this.featureService.state.subTitle = this.smartCityArticle?.code;
        this.featureService.state.showNewArticleButton = true;
        this.featureService.state.showRefreshButton = true;
        this.featureService.state.showBackButton = true;
        this.featureService.onRefresh.subscribe(() => this.update());
    }

    get createdOnDateFormat(): string {
        return this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN : XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.smartCityArticleID = this.dialogConfig.data.smartcityArticleID;
            this.smartCityArticle = this.dialogConfig.data.smartcityArticle;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
        }

        if (!XSUtils.isEmpty(this.smartCityArticleID)) {
            this.retrieveArticle();
        } else {
            if (XSUtils.isEmpty(this.smartCityArticleID)) {
                this.loaderService.startLoader(this.LOADER_ID);
                this.activatedRoute.params.pipe(first()).subscribe((params) => {
                    this.smartCityArticleID = params['id'];
                    this.retrieveArticle();
                });
            }
        }

        if (XSUtils.isEmpty(this.smartCityArticleID) && XSUtils.isEmpty(this.smartCityArticle)) {
            throw new Error('smartCityArticleID and smartCityArticle cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.smartCityArticleID) && !XSUtils.isEmpty(this.smartCityArticle)) {
            throw new Error('smartCityArticleID and smartCityArticle cannot both be set at the same time.');
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public isLoaderRunning() {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public update(): void {
        this.retrieveArticle();
    }

    private retrieveArticle(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.smartCityService
                .retrieve(this.smartCityArticleID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (article: LCESmartCityArticle) => {
                        this.smartCityArticle = article;
                        this.categoryChips = this.buildChips(this.smartCityArticle.categories?.map(category => category.name));
                        this.tagChips = this.buildChips(this.smartCityArticle.tags);
                        this.reviewers = this.smartCityArticle.reviewers;
                        if (!this.isDialog()) {
                            this.featureService.state.subTitle = this.smartCityArticle?.code;
                        }
                    },
                    error: (error) => (this.error = error)
                })
        );
    }

    private buildChips(strArray: string[] | undefined): XSChip[] {
        const chips: XSChip[] = [];
        if (XSUtils.isEmpty(strArray)) {
            return [];
        }
        strArray!.forEach(str => {
            chips.push({label: str});
        });
        return chips;
    }
}
